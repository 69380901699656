import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateServiceService {

  constructor(private readonly updates: SwUpdate) {
    this.updates.available.subscribe(event => {
      this.doAppUpdate();
    });
  }
  
  doAppUpdate() {
      this.updates.activateUpdate().then(() => document.location.reload());
    }
}
