import { Component, OnInit, Input } from '@angular/core';
import { BusquedaService } from '../busqueda.service'

@Component({
  selector: 'app-fichas',
  templateUrl: './fichas.component.html',
  styleUrls: ['./fichas.component.scss']
})
export class FichasComponent implements OnInit {
  @Input() estollegadelpadre: String; //any
  imagen ="";
  miembro: any;
  folio = "0";
  nombre = "";
  edad = "inactivo";
  region  = "";
  direccion = "";
  giro = "";
  agrupacion = "0";
  subagrupacion = "0";
  lider = "";
  cargo = "0";
  estatus = "0";
  constructor(private busqueda: BusquedaService) {
    this.busqueda.getMiembro("default").subscribe(
      res => {
        console.log(this.miembro);
        this.miembro = res;
        this.folio = this.miembro.folio;
        this.nombre = this.miembro.nombre;
        this.edad = this.miembro.edad;
        this.region = this.miembro.region;
        this.direccion = this.miembro.direccion;
        this.giro = this.miembro.giro;
        this.agrupacion = this.miembro.agrupacion;
        this.subagrupacion = this.miembro.subagrupacion;
        this.lider=this.miembro.lider;
        this.cargo = this.miembro.cargo;
        this.estatus = this.miembro.estado;
        var temp: string = this.miembro.foto;
        temp = "https://uson.juridicodelos.com" + temp.slice(2, temp.length + 1);
        this.imagen = temp;
        console.log(temp,this.miembro);
      },
      err => console.log(err),
    );

    
  }

  ngOnInit(): void {
  }
  actualiza() {
    this.busqueda.getMiembro(this.estollegadelpadre).subscribe(
      res => {
        this.miembro = res;
        this.folio = this.miembro.folio;
        this.nombre = this.miembro.nombre;
        this.edad = this.miembro.edad;
        this.region = this.miembro.region;
        this.direccion = this.miembro.direccion;
        this.giro = this.miembro.giro;
        this.agrupacion = this.miembro.agrupacion;
        this.subagrupacion = this.miembro.subagrupacion;
        this.lider=this.miembro.lider;
        this.cargo = this.miembro.cargo;
        this.estatus = this.miembro.estado;
        var temp: string = this.miembro.foto;
        temp = "https://uson.juridicodelos.com" + temp.slice(2, temp.length + 1);
        this.imagen = temp;
      },
      err => console.log("aqui", this.estollegadelpadre)
    );


  }
}
