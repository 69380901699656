import { Component, ViewChild, ElementRef, AfterViewInit, Input, Output, HostListener } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat } from '@zxing/library';
import { Result } from '@zxing/library';
import { Router } from '@angular/router';
import { FichasComponent } from './fichas/fichas.component';
import { PhoneLoginComponent } from './phone-login/phone-login.component';
import { AppUpdateServiceService } from './app-update-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('scrollframe', { static: false }) scrollFrame: ElementRef;
  usuario: string;
  flag: boolean;
  @ViewChild('datosDesdeElPadre', { static: false }) datosDesdeElPadre: FichasComponent;
  title = 'gtd-scanner';
  resultado = "";
  private itemContainer: any;
  private scrollContainer: any;
  private isNearBottom = true;

  constructor(private router: Router, public appUpDate: AppUpdateServiceService) {

  }
  ngAfterViewInit() {
    this.scrollContainer = this.scrollFrame.nativeElement;
  }
  scanSuccessHandler(resultado: string) {
    this.router.navigate(['/fichas']);
    resultado = this.decifrate(resultado)
    alert(resultado);
  }
  private scrollToBottom(): void {
    window.scroll({ // <- Scroll window instead of scrollContainer
      top: 600,
      left: 0,
      behavior: 'smooth'
    });
  }

  private isUserNearBottom(): boolean {
    const threshold = 100;
    const position = window.scrollY + window.innerHeight; // <- Measure position differently
    const height = document.body.scrollHeight; // <- Measure height differently
    return position > height - threshold;
  }

  @HostListener('window:scroll', ['$event']) // <- Add scroll listener to window
  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom();
  }

  decifrate(texto: string): string {
    var s = "";
    if (texto.length <= 9) {
      this.flag = false;
      s = String.fromCharCode(texto.charCodeAt(0) - 5) + String.fromCharCode(texto.charCodeAt(1) + 5) + String.fromCharCode(texto.charCodeAt(2) - 4) + String.fromCharCode(texto.charCodeAt(3) + 4) + String.fromCharCode(texto.charCodeAt(4) - 3) + String.fromCharCode(texto.charCodeAt(5) + 3) + String.fromCharCode(texto.charCodeAt(6) - 2) + String.fromCharCode(texto.charCodeAt(7) + 2) + String.fromCharCode(texto.charCodeAt(8) - 1) ;
    } else {
      this.flag = true;
      s = String.fromCharCode(texto.charCodeAt(0) - 5) + String.fromCharCode(texto.charCodeAt(1) + 5) + String.fromCharCode(texto.charCodeAt(2) - 4) + String.fromCharCode(texto.charCodeAt(3) + 4) + String.fromCharCode(texto.charCodeAt(4) - 3) + String.fromCharCode(texto.charCodeAt(5) + 3) + String.fromCharCode(texto.charCodeAt(6) - 2) + String.fromCharCode(texto.charCodeAt(7) + 2) + String.fromCharCode(texto.charCodeAt(8) - 1) + String.fromCharCode(texto.charCodeAt(9) + 1) ;
    }
    return s;
  }
  getOutputval(usuarioval: string) {
    if (usuarioval) {
      this.usuario = usuarioval;
    }

  }
}
