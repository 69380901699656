// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBZkukMBrzbqvOpkM6bZv0P6g2Dj2SGfDI",
    authDomain: "gtdscanner.firebaseapp.com",
    databaseURL: "https://gtdscanner.firebaseio.com",
    projectId: "gtdscanner",
    storageBucket: "gtdscanner.appspot.com",
    messagingSenderId: "308461400886",
    appId: "1:308461400886:web:f7b9a6a45edf8e9778b997"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
