<br>
<br>
<div [hidden]="user">
  <div class="container text-center">
    <label for="usu" class="">Ingresa tu usuario de coordinador</label><br> 
    <input type="text" name="usu" [(ngModel)]="usu" class="input"><br> <br>
    <label for="pass">Ingresa tu contraseña.</label><br>
    <input type="text" name="pass" [(ngModel)]="pas" class="input">
  </div>
  <div class="container text-center">
    <br>
    <div id="recaptcha-container"></div>

    <hr>
    <button class="btn btn-primary" (click)="sendLoginCode()">Continuar</button>

    <!-- div *ngIf="windowRef.confirmationResult">
      <hr>
      <label for="code">Ingresa tu código SMS aquí</label><br>
      <input type="text" name="code" [(ngModel)]="verificationCode" class="input"> <br><br>

      <button class="btn btn-primary" (click)="verifyLoginCode()">Verifica</button>
    </div>-->

  </div>


  <div *ngIf="user">

    <div class="notification is-success">Correctamente ingresado!</div>

  </div>

</div>