import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { WindowService } from '../window.service';
import { BusquedaService } from '../busqueda.service'
import * as firebase from 'firebase'
import { config } from 'rxjs';
import { AppComponent } from '../app.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-phone-login',
  templateUrl: './phone-login.component.html',
  styleUrls: ['./phone-login.component.scss']
})
export class PhoneLoginComponent implements OnInit {
  @Output() usuarioval = new EventEmitter<any>();
  phoneNumber = "";
  verificationCode: string;
  windowRef: any;
  code: any;
  user: any;
  usu: string;
  pas: string;
  hide = true;
  firebase: {
    apiKey: "AIzaSyBZkukMBrzbqvOpkM6bZv0P6g2Dj2SGfDI",
    authDomain: "gtdscanner.firebaseapp.com",
    databaseURL: "https://gtdscanner.firebaseio.com",
    projectId: "gtdscanner",
    storageBucket: "gtdscanner.appspot.com",
    messagingSenderId: "308461400886",
    appId: "1:308461400886:web:f7b9a6a45edf8e9778b997"
  };

  constructor(private toaster: ToastrService,
    private windowService: WindowService, private busqueda: BusquedaService) {
    /*this.windowRef=this.windowService.windowRef;
    this.phoneNumber="+57226209006";
    firebase.initializeApp(this.firebase);*/

  }

  ngOnInit(): void {
    this.windowRef = this.windowService.windowRef;
    /*this.windowRef.recaptchaVerifier=new firebase.auth.RecaptchaVerifier('recaptcha-container')
    this.windowRef.recaptchaVerifier
                  .render()
                  .then( widgetId => {

                    this.windowRef.recaptchaWidgetId = widgetId
    });*/
  }
  sendLoginCode() {
    const appVerifier = this.windowRef.recaptchaVerifier;
    this.busqueda.login(this.usu, this.pas).subscribe(
      res => {this.code = res;if (this.code.codigo != "false") {
        var tempo: string = String(this.code.codigo);
        console.log(this.code);
        var tempo2 = tempo.charAt(0) + tempo.charAt(1) + tempo.charAt(2) + tempo.charAt(3) + tempo.charAt(4);
        console.log(tempo2);
        if (tempo2 == "Killa") {
          this.toaster.success("Usuario correcto", "USON");
          this.usuarioval.emit(this.usu);
          this.user = this.usu;
        } else {
          this.toaster.error("Usuario o contraseña incorrectos", "USON");
        }
      } else {
        this.toaster.error("Usuario o contraseña incorrectos", "USON");
      }},
      err => console.log(err),
    );
    
  }
  verifyLoginCode() {
    this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then(result => {
        this.user = result.user;
        this.usuarioval.emit(result.user.uid);
        this.toaster.success("Código correcto", "GTD");
      })
      .catch(error => this.toaster.error("El código ingresado no es correcto", "GTD"));
  }
}
