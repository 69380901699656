<!doctype html>
<html lang="en">

<head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <!-- Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
        integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous">

    <title>Escáner QR</title>
</head>

<body>
    <h1>Datos del miembro</h1>
    <hr>
    <br>
    <table class="table table-striped table-dark" width="600">
        <thead>
            <tr>
                <th scope="row">Folio</th>
                <td>{{folio}}</td>
            </tr>
        </thead>
        <tbody>
            <tr>

                <th scope="row">Nombre</th>
                <td>{{nombre}}</td>
            </tr>
            <tr>

            <tr>

                <th scope="row">Edad</th>
                <td>{{edad}}</td>
            </tr>
            <tr>

                <th scope="row">Región</th>
                <td>{{region}}</td>
            </tr>
            <tr>

                <th scope="row">Dirección</th>
                <td>{{direccion}}</td>
            </tr>
            <tr>

                <th scope="row">Giro</th>
                <td>{{giro}}</td>
            </tr>
            <tr>

                <th scope="row">Agrupación</th>
                <td>{{agrupacion}}</td>
            </tr>
            <tr>

                <th scope="row">Subagrupación</th>
                <td>{{subagrupacion}}</td>
            </tr>
            <tr>

                <th scope="row">Líder</th>
                <td>{{lider}}</td>
            </tr>
            <tr>

                <th scope="row">Cargo</th>
                <td [ngClass]= "{
                    'bg-danger': cargo == '',
                    'bg-warning': cargo == 'Coordinador',
                    'bg-success': cargo == 'Agremiado'
                  }" >{{cargo}}</td>
            </tr>
            <tr>

                <th scope="row">Estatus</th>
                <td  [ngClass]= "{
                    'bg-danger': estatus == 'inactivo',
                    'bg-success': estatus == 'activo'
                  }" >{{estatus}}</td>
            </tr>
        </tbody>
    </table>
    <div class="container mt-5">
        <div class="row">
            <div class="col text-center">
                <img src="{{imagen}}" class="img-fluid" alt="Responsive image">
            </div>
        </div>
    </div>
    <!-- Optional JavaScript -->
    <!-- jQuery first, then Popper.js, then Bootstrap JS -->
    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
        integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
        crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"
        integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN"
        crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"
        integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV"
        crossorigin="anonymous"></script>
</body>

</html>