import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { identifierName } from '@angular/compiler';
import {miembro} from './models/miembro'

@Injectable({
  providedIn: 'root'
})
export class BusquedaService {

  API_URL='https://www.uson.juridicodelos.com/server'
  constructor(private http:HttpClient) {}
    getMiembro(id:String){
      return this.http.get(`${this.API_URL}/get.php?folio=${id}`);
    }
    getMiembros() {
      return this.http.get(`${this.API_URL}/getAll.php`);
    }
    login(usu:string, con:string){
      return this.http.get(`${this.API_URL}/logini.php?usu=${usu}&con=${con}`);
    }
}
